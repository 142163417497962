import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "Search"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Out of stock"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Low stock"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "No items available"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Will become available for purchase on {date} at {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "You might like"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Choose payment method"
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Provide email address"
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Account"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "My Account"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Order history"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Address book"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Log Out"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "Firstname"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Lastname"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Password"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Qauntity"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximum quantity is {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Qty"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Subtotal"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Shipping"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Promo code"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Total Price"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Checkout"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Email"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Loading"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Products"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Price"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Variant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Phone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Phone number: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Save"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Add"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "FILTERS"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "CLEAR FILTERS"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Cancel"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Home"
  },
  paymentHistories: {
    "id": "intl.commonMessages.paymentHistories",
    defaultMessage: "Payment histories"
  },
  orderProduct: {
    "id": "intl.commonMessages.orderProduct",
    defaultMessage: "Order Product"
  },
  carAndPart: {
    "id": "intl.commonMessages.carAndPart",
    defaultMessage: "Car & Part"
  },
  cargo: {
    "id": "intl.commonMessages.cargo",
    defaultMessage: "Cargo"
  },
  aboutUs: {
    "id": "intl.commonMessages.aboutUs",
    defaultMessage: "About us"
  },
  contactUs: {
    "id": "intl.commonMessages.contactUs",
    defaultMessage: "Contact us"
  },
  faq: {
    "id": "intl.commonMessages.faq",
    defaultMessage: "FAQ"
  },
  termCondition: {
    "id": "intl.commonMessages.termCondition",
    defaultMessage: "Terms & Condition"
  },
  userCode: {
    "id": "intl.commonMessages.userCode",
    defaultMessage: "User code:"
  },
  login: {
    "id": "intl.commonMessages.login",
    defaultMessage: "Login"
  },
  loginDetail: {
    "id": "intl.commonMessages.loginDetail",
    defaultMessage: "Login to Unurshop"
  },
  continue: {
    "id": "intl.commonMessages.continue",
    defaultMessage: "Continue"
  },
  alertCheckEmail: {
    "id": "intl.commonMessages.alertCheckEmail",
    defaultMessage: "Please check your email and confirm your account"
  },
  alertSendEmail: {
    "id": "intl.commonMessages.alertSendEmail",
    defaultMessage: "Send email"
  },
  createAnAccount: {
    "id": "intl.commonMessages.createAnAccount",
    defaultMessage: "Create an account"
  },
  forgottenPassword: {
    "id": "intl.commonMessages.forgottenPassword",
    defaultMessage: "Forgotten password?"
  },
  clickHere: {
    "id": "intl.commonMessages.clickHere",
    defaultMessage: "Click here"
  },
  back: {
    "id": "intl.commonMessages.back",
    defaultMessage: "Back"
  },
  searchText: {
    "id": "intl.commonMessages.searchText",
    defaultMessage: "Search monwol.com"
  },
  serviceFee: {
    "id": "intl.commonMessages.serviceFee",
    defaultMessage: "Service fee £0.00"
  },
  homeSlogan: {
    "id": "intl.commonMessages.homeSlogan",
    defaultMessage: "Order what you want from the UK"
  },
  loadMore: {
    "id": "intl.commonMessages.loadMore",
    defaultMessage: "Load More"
  },
  addToBasket: {
    "id": "intl.commonMessages.addToBasket",
    defaultMessage: "Add to basket"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Address"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Shipping"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Payment"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Review"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Үргэлжлүүлэх"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Continue to Payment"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Үргэлжлүүлэх"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Захиалга өгөх"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Шинэ хаяг оруулах"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "SHIPPING METHOD"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "Төлбөрийн картын хаяг"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "Төлбөрийн нөхцөл"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "Захиалга хянах"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Хүлээн авах хаяг"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "Нүүр хуудас"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Clear..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Price High-Low"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Төлөөгүй"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Төлбөрийн үлдэлдэлтэй"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Бүрэн төлсөн"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Хэсэгчлэн буцаалттай"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Бүгдийн буцаасан"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Төлбөр хүлээгдэж байгаа"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Биелээгүй"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Хэсэгчлэн биелсэн"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Биелсэн"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Цуцалсан"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}